<mat-card *ngIf="news$ | async; let news" class="mb-3 news detail">
  <mat-card-header>
    <img
      src="/vendor/logos/blattwerkzeug-logo.svg"
      class="news-avatar"
      mat-card-avatar
    />
    <mat-card-title>{{news.title[locale]}}</mat-card-title>
    <mat-card-subtitle i18n="@@newsPublicationDate">
      Neuigkeiten vom {{news.publishedFrom | date:'dd.MM.yyyy'}}
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content
    [innerHTML]="news.renderedTextFull[locale]"
  ></mat-card-content>
</mat-card>
