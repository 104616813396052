<h1 *ngIf="data.caption" mat-dialog-title>{{ data.caption }}</h1>
<div mat-dialog-content>
  <p>{{ data.description }}</p>
</div>
<div mat-dialog-actions>
  <ng-container [ngSwitch]="dialogueType">
    <button *ngSwitchCase="'alert'" mat-button (click)="onClose()">Ok</button>
    <ng-container *ngSwitchCase="'confirm'">
      <button mat-button (click)="onClose(true)">Ja</button>
      <button mat-button (click)="onClose(false)">Nein</button>
    </ng-container>
  </ng-container>
</div>
