<!-- If a message is passed to the dialog -->
<div
  *ngIf="hasMessage"
  mat-dialog-header
  class="alert"
  [ngClass]="{
    'alert-danger': messageType == 'error',
    'alert-warning': messageType == 'warning'
  }"
  role="alert"
>
  {{ message }}
</div>

<div mat-dialog-content class="flex-column authentication">
  <div>
    <h1 i18n="@@authDialogHeaderSignIn">Anmelden</h1>
  </div>
  <providers-all-buttons></providers-all-buttons>
</div>
