<!-- Sidebar -->
<mat-sidenav-container>
  <mat-sidenav
    #sideNav
    [mode]="sidebarMode$ | async"
    [opened]="!(isMobile$ | async)"
  >
    <!-- Sidebar item output -->
    <mat-nav-list id="main-nav">
      <ng-container *ngFor="let nav of navItems$ | async">
        <ng-container *ngIf="userHasRoles$(nav.requireRoles) | async">
          <ng-container [ngSwitch]="nav.type">
            <!-- Rendering internal links -->
            <a
              *ngSwitchCase="'link'"
              mat-list-item
              [routerLink]="nav.route"
              routerLinkActive="router-link-active"
              [routerLinkActiveOptions]="{ exact: !!nav.highlightExact }"
            >
              <span
                *ngIf="nav.icon"
                class="fa fa-fw fa-{{ nav.icon }}"
                aria-hidden="true"
              ></span>
              {{ nav.text | currentLanguage }}
            </a>
            <!-- Rendering external links -->
            <a *ngSwitchCase="'external'" mat-list-item [href]="nav.url">
              <span
                *ngIf="nav.icon"
                class="fa fa-fw fa-{{ nav.icon }}"
                aria-hidden="true"
              ></span>
              {{ nav.text | currentLanguage }}
            </a>
            <!-- Rendering Dividers -->
            <mat-divider *ngSwitchCase="'divider'"></mat-divider>
            <!-- Rendering Headers -->
            <h3 *ngSwitchCase="'header'" mat-subheader>
              {{ nav.text | currentLanguage }}
            </h3>
            <!-- Rendering fillers -->
            <div *ngSwitchCase="'fill'" class="fill"></div>
          </ng-container>
        </ng-container>
      </ng-container>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content class="content">
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
