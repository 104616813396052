<app-requires-javascript>
  <ng-container active>
    <button
      mat-icon-button
      [matMenuTriggerFor]="naturalLang"
      aria-label="Language Selection"
    >
      {{ localeFlag }}
    </button>
    <mat-menu #naturalLang="matMenu">
      <a
        mat-menu-item
        *ngFor="let localItem of locales"
        [href]="urlForLanguage(localItem.token)"
      >
        <span>{{localItem.flag}} {{localItem.name}}</span>
      </a>
    </mat-menu>
  </ng-container>
  <ng-container inactive>
    <a
      *ngFor="let localeItem of locales"
      [href]="urlForLanguage(localeItem.token)"
    >
      {{ localeItem.flag }}
    </a>
  </ng-container>
</app-requires-javascript>
