import { UserDescription } from "./auth/user.description";

/**
 * Matches the JSON generated by the server side Ruby Exception
 * `EsqulinoError` and must be manually kept in sync.
 */
export interface ServerErrorDescription {
  code: number;
  type: string;
  implError: boolean;
  message: string;
  innerException?: string;
}

/**
 * Something went wrong when querying the database.
 */
export interface DatabaseQueryErrorDescription extends ServerErrorDescription {
  project: string;
  sql: string;
  params: any;
}

/**
 * The user was unexpectedly logged out during a operation that should not result
 * in a logout. Business logic services shouldn't ever see this exception as it
 * is handled by a global interceptor.
 *
 * @see UnexpectedLogoutInterceptor
 */
export interface UnexpectedLogoutDescription extends ServerErrorDescription {
  type: "UnexpectedLogout";
  newUser: UserDescription;
}

export function isUnexpectedLogoutDescription(
  obj: any
): obj is UnexpectedLogoutDescription {
  return obj && obj.type === "UnexpectedLogout";
}
