<app-table-paginator-graphql [queryData]="queryData" matSort>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let project">
      <a [routerLink]="['/', 'editor', project | urlFriendlyId]">
        {{ typed(project).name | currentLanguage }}
      </a>
    </td>
  </ng-container>
  <ng-container matColumnDef="slug">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Slug</th>
    <td mat-cell *matCellDef="let project">
      <code>{{ typed(project).slug }}</code>
    </td>
  </ng-container>
  <ng-container matColumnDef="createdAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Erstellt</th>
    <td mat-cell *matCellDef="let project">
      {{ typed(project).createdAt | date: "yyyy.MM.dd" }}
    </td>
  </ng-container>
</app-table-paginator-graphql>
