<h1 class="mt-3" i18n="@@news">Neuigkeiten</h1>
<mat-card *ngFor="let item of (newsList$ | async)" class="mb-3 news list">
  <mat-card-header>
    <img
      src="/vendor/logos/blattwerkzeug-logo.svg"
      class="news-avatar"
      mat-card-avatar
    />
    <mat-card-title [innerHTML]="item.title[locale]"></mat-card-title>
    <mat-card-subtitle i18n="@@newsPublicationDate">
      Neuigkeiten vom {{item.publishedFrom | date:'dd.MM.yyyy'}}
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content
    [innerHTML]="item.renderedTextShort[locale]"
  ></mat-card-content>
  <mat-card-actions>
    <a
      mat-button
      [routerLink]="[ '/news/details', item.id ]"
      i18n="@@continueReading"
    >
      Weiterlesen ...
    </a>
  </mat-card-actions>
</mat-card>
