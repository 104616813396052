<h1 i18n="teacher page headline@@teacherPageHeadline">
  Datenbanken und HTML für den Unterricht
</h1>
<p i18n="teacher page description@@teacherPageDescription">
  Bei BlattWerkzeug handelt es sich um eine Web-Anwendung, mit der Schülerinnen
  und Schüler an Datenbanken und Webentwicklung herangeführt werden können. Der
  Arbeitstitel für dieses Projekt lautete "Scratch für Datenbanken
  Webanwendungen" und beschreibt das Selbstverständnis von BlattWerkzeug recht
  sprechend.
</p>

<video-display></video-display>
