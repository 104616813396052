<h1>Versionsinformationen</h1>
<table class="table">
  <tr>
    <td><code>GIT_HASH</code></td>
    <td>{{ GIT_HASH }}</td>
  </tr>
  <tr>
    <td><code>BUILD_DATE</code></td>
    <td>{{ BUILD_DATE }}</td>
  </tr>
  <tr>
    <td><code>LOCALE_ID</code></td>
    <td><code>{{ LOCALE_ID }}</code></td>
  </tr>
  <tr>
    <td>Fehlerberichte übermitteln?</td>
    <td><code>{{ ERROR_REPORTING }}</code></td>
  </tr>
</table>

<button class="btn btn-danger" (click)="onRaiseError()">
  Test-Fehler erzeugen
</button>
