<div class="body-nav-container">
  <app-toolbar>
    <ng-container position="toggle">
      <button mat-icon-button (click)="navToggle()">
        <span class="fa fa-bars" aria-hidden="true"></span>
      </button>
    </ng-container>
  </app-toolbar>

  <side-nav-selector>
    <!-- container comes from Bootstrap, it sets up the different "snapping" viewport widths#
          front comes from the framework and is used in various CSS rules that should only apply at front
          mat-typography comes from angular material and styles native elements-->
    <div class="container front mat-typography">
      <flash-message-list></flash-message-list>
      <router-outlet></router-outlet>
    </div>
  </side-nav-selector>
</div>
