<mat-toolbar color="primary">
  <!-- The menu toggle (if applicable) -->
  <app-requires-javascript>
    <ng-container active>
      <ng-content select="[position=toggle]"></ng-content>
    </ng-container>
    <ng-container inactive>
      <a [routerLink]="['nav']" mat-icon-button>
        <span class="fa fa-bars" aria-hidden="true"></span>
      </a>
    </ng-container>
  </app-requires-javascript>

  <!-- "Back" button to main page -->
  <a mat-button [routerLink]="'/about'">
    <img
      src="/vendor/logos/blattwerkzeug-logo.svg"
      height="20px"
      title="BlattWerkzeug"
    />
    <img
      src="/vendor/logos/blattwerkzeug-caption.svg"
      height="20px"
      title="BlattWerkzeug"
    />
  </a>

  <!-- Static content at the beginning of this toolbar -->
  <ng-content select="[position=start]"></ng-content>

  <!-- Things the user might do here -->
  <ng-container
    *ngFor="let toolbarItem of (toolbarItems$ | async)"
    [cdkPortalOutlet]="toolbarItem"
  ></ng-container>

  <!-- Filling out the space in the middle -->
  <span class="fill-space"></span>

  <!-- Static contentent at the end of this toolbar -->
  <ng-content select="[position=end]"></ng-content>

  <!-- Possibly allowing the user to login -->
  <user-buttons *ngIf="loginEnabled || (isLoggedIn$ | async)"></user-buttons>

  <!-- Changing the natural language of the application should be possible everywhere -->
  <natural-language-selector></natural-language-selector>
</mat-toolbar>
