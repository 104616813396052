<mat-menu #userMenu="matMenu">
  <button
    mat-menu-item
    [routerLink]="['/user/settings']"
    i18n="@@userMenuSettings"
  >
    Einstellungen
  </button>

  <button
    mat-menu-item
    [routerLink]="['/user/projects']"
    i18n="@@userMenuProjects"
  >
    Projekte
  </button>

  <button mat-menu-item (click)="onLogout()" i18n="@@userMenuLogout">
    <span class="fa fa-fw fa-sign-out"></span>
    Abmelden
  </button>
</mat-menu>

<is-logged-in class="user-buttons">
  <ng-container logged_in>
    <button mat-button [matMenuTriggerFor]="userMenu">
      <span class="fa fa-fw fa-user-circle-o" aria-hidden="true"></span>
      {{ userDisplayName$ | async }}
    </button>
  </ng-container>

  <ng-container logged_out>
    <button mat-button (click)="openDialog('signIn')">
      <span class="fa fa-fw fa-sign-in" aria-hidden="true"></span>
      <ng-container i18n="@@userMenuSignIn">Anmelden</ng-container>
    </button>

    <button mat-button (click)="openDialog('signUp')">
      <span class="fa fa-fw fa-user-plus" aria-hidden="true"></span>
      <ng-container i18n="@@userMenuRegister">Registrieren</ng-container>
    </button>
  </ng-container>
</is-logged-in>
