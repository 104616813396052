<div class="row">
  <div class="col-md-6">
    <h1>
      <ng-container
        i18n="imprint contact information headline@@imprintContactInformationHeadline"
      >
        Kontakt-Informationen
      </ng-container>
      <small i18n="imprint headline law paragraph@@imprintHeadlineLawParagraph">
        nach §5 TMG
      </small>
    </h1>
    <p i18n="imprint contact information text@@imprintContactInformationText">
      Im einfachsten Fall erreichen Sie mich per E-Mail oder über die
      nebenstehenden Platformen. In allen anderen Fällen erreichen Sie mich auch
      postalisch:
    </p>
    <p>
      Fachhochschule Wedel
      <br />
      Marcus Riemer
      <br />
      Feldstraße 143
      <br />
      22880 Wedel
      <br />
    </p>
  </div>
  <div class="col-md-6">
    <h1>Marcus Riemer</h1>
    <dl class="">
      <dt>
        <a
          href="http://www.fh-wedel.de/~mri"
          i18n="imprint website link title@@imprintWebsiteLinkTitle"
        >
          FH Wedel Mitarbeiterseite
        </a>
      </dt>
      <dd i18n="imprint website description@@imprintWebsiteDescription">
        Übersicht &amp; Informationen zu meiner Tätigkeit an der FH Wedel.
      </dd>
      <dt>
        <a
          href="https://bitbucket.org/marcusriemer/esqulino"
          i18n="imprint bitbucket link text@@imprintBitbucketLinkText"
        >
          BlattWerkzeug auf Bitbucket
        </a>
      </dt>
      <dd
        i18n="imprint bitbucket link description@@imprintBitbucketLinkDescription"
      >
        Hier finden sich die vollständigen Quellen zum herunterladen.
      </dd>
      <dt>
        mri@FH-Wedel.de |
        <a href="/static/pgp/mrifhw.pub">Public Key</a>
      </dt>
      <dd
        i18n="imprint contact email description@@imprintContactEmailDescription"
      >
        Anfragen zu BlattWerkzeug bitte an diese Adresse
      </dd>
    </dl>
    <div class="alert alert-info" role="alert">
      <strong i18n="imprint signed email note@@imprintSignedEmailNote">
        Ich verschicke meine E-Mails grundsätzlich digital signiert, wenn
        möglich auch verschlüsselt.
      </strong>
      <ng-container
        i18n="imprint signed email instructions@@imprintSignedEmailInstructions"
      >
        Wer seine digitale Kommunikation ebenfalls verschlüsseln möchte, findet
        eine gute Anleitung bei
      </ng-container>
      <a
        class="alert-link"
        href="https://netzpolitik.org/2013/anleitung-so-verschlusselt-ihr-eure-e-mails-mit-pgp/"
      >
        netzpolitik.org
      </a>
      .
    </div>
  </div>
</div>
