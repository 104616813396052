<h2 i18n="pupil page headline@@pupilPageHeadline">
  Dein Einstieg in die Web-Entwicklung
</h2>

<div class="alert alert-danger" role="alert">
  <strong i18n="pupil page alert text@@pupilPageAlertText">
    Sorry, so weit ist dieses Projekt noch nicht 😞
  </strong>
  <ng-container i18n="pupil page alert description@@pupilPageAlertDescription">
    Für den Augenblick suchen wir vor allem nach Lehrkräften, die zu unserem
    Projekt Feedback geben.
  </ng-container>
</div>
