<div class="body-nav-container">
  <app-toolbar>
    <ng-container position="toggle">
      <button mat-icon-button (click)="navToggle()">
        <span class="fa fa-bars" aria-hidden="true"></span>
      </button>
    </ng-container>
  </app-toolbar>

  <side-nav-selector>
    <div class="container-fluid mat-typography">
      <flash-message-list></flash-message-list>
      <router-outlet></router-outlet>
    </div>
  </side-nav-selector>
</div>
